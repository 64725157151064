<template>
  <div>
    <eden-page-header :title="'Orders & Schedule'">
      <template slot="actions">
        <orders-actions
          v-if="allowAccessFor(['admin', 'gardener', 'operations'])"
          @action="getOrders"
        />
      </template>
    </eden-page-header>
    <eden-page-stats
      :title="'Overview'"
      :loading="summary.loading"
      :stats="stats"
      :show-periods="true"
      :periods="periods"
      @set-period="getOrdersSummary"
    />
    <eden-loader v-if="loading" />
    <el-tabs v-else v-model="tab" @tab-click="updateRouteQuery">
      <el-tab-pane label="Orders" name="orders">
        <orders-schedule />
      </el-tab-pane>
      <el-tab-pane
        v-for="(schedule, i) in schedules"
        :key="i"
        :label="schedule.label"
        :name="schedule.name"
      >
        <component :is="`orders-schedule-${schedule.name}`" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import orders from "@/requests/orders";
import * as actions from "@/store/action-types";

export default {
  name: "Orders",
  components: {
    OrdersActions: () => import("@/components/Orders/Actions/OrdersActions"),
    OrdersSchedule: () => import("@/components/Orders/Schedule/OrdersSchedule"),
    OrdersScheduleBeauty: () =>
      import("@/components/Orders/Schedule/Beauty/OrdersScheduleBeauty"),
    OrdersScheduleCleaning: () =>
      import("@/components/Orders/Schedule/Cleaning/OrdersScheduleCleaning"),
    OrdersScheduleLaundryPickup: () =>
      import(
        "@/components/Orders/Schedule/Laundry/OrdersScheduleLaundryPickup"
      ),
    OrdersScheduleLaundryDelivery: () =>
      import(
        "@/components/Orders/Schedule/Laundry/OrdersScheduleLaundryDelivery"
      ),
    OrdersScheduleMeal: () =>
      import("@/components/Orders/Schedule/Meal/OrdersScheduleMeal"),
  },
  data() {
    return {
      loading: false,
      tab: "meal",
      period: "today",
      periods: {
        today: "Today",
        tomorrow: "Tomorrow",
        thisweek: "This week",
      },
      summary: {
        loading: false,
        data: {},
      },
    };
  },
  computed: {
    stats() {
      const summary = this.summary.data;
      return [
        {
          label: "All time orders",
          figure: this.formatFigure(summary.total_orders_alltime),
        },
        {
          label: `Orders ${this.period}`,
          figure: this.formatFigure(summary.total_orders),
        },
        {
          label: `One-time orders ${this.period}`,
          figure: this.formatFigure(summary.total_one_time_orders),
        },
        {
          label: "Completed orders",
          figure: this.formatFigure(summary.total_orders_completed),
        },
        {
          label: "Percentage completion",
          figure: this.formatPercentage(summary.percentage_of_orders_completed),
        },
      ];
    },
    services() {
      return this.$store.getters.services;
    },
    schedules() {
      let schedules = [];
      this.services.forEach((service) => {
        switch (service.slug) {
          case "beauty":
            schedules.push({
              access: ["admin"],
              label: "Beauty Schedule",
              name: "beauty",
            });
            break;
          case "cleaning":
            schedules.push({
              access: ["admin"],
              label: "Cleaning Schedule",
              name: "cleaning",
            });
            break;
          case "laundry":
            schedules.push({
              access: ["admin"],
              label: "Laundry Pickup Schedule",
              name: "laundry-pickup",
            });
            schedules.push({
              access: ["admin"],
              label: "Laundry Delivery Schedule",
              name: "laundry-delivery",
            });
            break;
          case "meal":
            schedules.push({
              access: "all",
              label: "Food Schedule",
              name: "meal",
            });
            break;
          default:
            break;
        }
      });
      return schedules;
    },
  },
  created() {
    this.getOrdersSummary({ period: this.period });
    this.loading = true;
    this.$store.dispatch(actions.GET_SERVICE_PARTNERS_LIST).then(() => {
      this.loading = false;
    });
    let { t } = this.$route.query;
    this.tab = t ? t : "meal";
  },
  methods: {
    updateRouteQuery(tab) {
      const { t } = this.$route.query;
      if (t === this.tab) {
        return false;
      }
      this.$router.push({
        name: "orders.index",
        query: { t: tab.name },
      });
    },
    getOrdersSummary({ period, from, to }) {
      this.summary.loading = true;
      const { start_date, end_date } = this.formatStartAndEndDate({
        period,
        from,
        to,
      });

      orders
        .summary(start_date, end_date)
        .then((response) => {
          const { status, data } = response.data;
          if (status) {
            this.summary.data = data;
            this.period = period;
          }
          this.summary.loading = false;
        })
        .catch((error) => {
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    getOrders() {
      this.loading = true;
      this.summary.loading = true;

      setTimeout(() => {
        this.loading = false;
        this.summary.loading = false;
      }, 1500);
    },
  },
};
</script>
